<template>
  <!-- 意见反馈 列表 -->
  <div class="record-list">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
      <div class="item" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="left">
            <div class="row">意见类型：{{ item.opinionTypeName }}</div>
            <div class="time row van-multi-ellipsis--l2">意见内容：{{ item.description }}</div>
            <div class="time row">上报时间：{{ item.createTime }}</div>
          </div>
          <div class="right">
            <span class="success" v-if="item.isHandle == 1">已处理</span>
            <span class="wait" v-else>未处理</span>
          </div>
        <!-- <div class="top">
          <div class="title van-ellipsis">意见类型：{{ item.opinionTypeName }}</div>
          <div class="title van-ellipsis">意见内容：{{ item.description }}</div>
        </div>
        <div class="time">
          <span>上报时间：{{ item.createTime }}</span>
          <div class="tags">
            <van-tag
              size="large"
              color="#fff"
              :text-color="item.isHandle == '1' ? '#40A9FF' : '#E61E16'"
              >{{ item.isHandle == '1' ? '已处理' : '未处理' }}</van-tag
            >
          </div>
        </div> -->
      </div>
    </van-list>
  </div>
</template>
<script>
import { getMyEventList } from '@/api/eventApi'
export default {
  data() {
    return {
      eventType: 5,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
    }
  },
  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    goDetail(id) {
      let path = '/myOpinionDetail?id='
      this.$router.push({
        path: path + id,
      })
    },
    getList() {
      if (this.currentList !== 0) {
        // eventType： 0事件上报 1民事调解 2居民服务申请 3警情上报
        getMyEventList({
          openid: this.$store.getters.openId,
          // category: 1,
          // type: 3,
          eventType: this.eventType,
          current: this.page,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.list = this.list.concat(res.data.records)
            this.page++
          }
        })
      } else {
        this.finished = true
      }
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ml {
  margin-left: 5px;
}
.diyCell {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px 10px 10px 20px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #c0e7fe;
  display: flex;
  flex-direction: column;
  .top {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-bottom: 3px;
    .title {
      flex: 1;
      font-size: 14px;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .time {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #b9b9b9;
    font-size: 12px;
    .tags {
      width: 120px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
